import getClient from "../client";
import { TAGS_CATEGORIES_PATH } from "../routes/user";
import { sortCweTags } from "../user/helpers/catalog/filtersGenerators";

/**
 * Processes and sorts CWE tags for both old and new data structures SHRPA-742
 * @param {Array} cweTags - Array of CWE tag objects
 * @returns {Array} Processed and sorted array of CWE tag objects
 */
const processCweTags = cweTags => {
  if (!Array.isArray(cweTags)) {
    return [];
  }

  const processedTags = cweTags.map(item => {
    if (item.cweTag) {
      // New structure: Merge lessonGroupsCount into cweTag object
      return {
        ...item.cweTag,
        lessonGroupsCount: item.lessonGroupsCount ?? null,
      };
    }
    // Old structure: Return item unchanged
    return {
      ...item,
      lessonGroupsCount: null, // Explicitly add lessonGroupCount for consistency
    };
  });

  return sortCweTags(processedTags, "name");
};

export default {
  state: {
    tagsData: {
      tagsCategories: [],
      cweTags: [],
    },
    isLoading: true,
  },

  reducers: {
    setData: (state, data) => ({ ...state, tagsData: data }),
    setLoading: (state, isLoading) => ({ ...state, isLoading }),
  },

  effects: dispatch => ({
    async loadAsync() {
      dispatch.tagsCategories.setLoading(true);
      const { data } = await getClient().get(TAGS_CATEGORIES_PATH);

      if (data) {
        const tagsCategories = data.tagsCategories.filter(tc => tc.tags.length);
        const languageTags = tagsCategories.find(tc => tc.name.toLowerCase() === "language");
        const language = languageTags ? languageTags.tags : [];

        window.availableLanguages = language;

        data.cweTags = processCweTags(data.cweTags);

        const updatedData = { ...data, tagsCategories };
        dispatch.tagsCategories.setData(updatedData);
      }
      dispatch.tagsCategories.setLoading(false);
    },
  }),
};
