import React, { Suspense, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import T from "prop-types";
import { Routes, Route, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { getIsSjAdminFromStorage } from "./helpers/user";
import { retrieveAuthenticatedUserInfo } from "./helpers/authService";
import { getPathForRedirect, removePathForRedirect } from "./helpers/redirectHelpers";
import { runIntercomScript } from "./helpers/intercom";
import { runAnalyticsScript } from "./helpers/analytics";
import EmptyPageLoader from "./components/EmptyPageLoader";
import ChooseTenantModal from "./user/components/common/modals/ChooseTenantModal";
import {
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  SAML_SJ_ADMIN_LOGIN_PATH,
  SJ_ADMIN_SIGN_IN_PATH,
} from "./routes/landing";
import { ADMIN_BASE } from "./admin/routes";
import HeRedirectApp from "./HeRedirectApp";
import { useTrackTime } from "./hooks/useTrackTime";
import {
  LESSON_PATH,
  ASSESSMENT_PATH,
  EXPERIMENT_PATH,
  MISSION_EXPERIMENT_PATH,
  CATALOG_LESSON_PATH,
  CATALOG_EXPERIMENT_PATH,
  CATALOG_ASSESSMENT_PATH,
} from "./constants/trackablePaths";
import FullScreenPreloader from "./components/FullScreenPreloader";
import { NetworkError } from "./components/NetworkError/NetworkError";
import { datadogRum } from "@datadog/browser-rum";
import { runSegmentIo } from "./helpers/segmentIo";

const FrontendApp = React.lazy(() => import("./FrontendApp"));
const AdminFrontendApp = React.lazy(() => import("./AdminFrontendApp"));
const SjAdminApp = React.lazy(() => import("./sjadmin/SjAdminApp"));
const LandingApp = React.lazy(() => import("./landing/LandingApp"));

const TryUsApp = React.lazy(() => import("./TryUsFrontendApp"));
const ScormApp = React.lazy(() => import("./scorm/ScormApp"));

const REDIRECT_PATHS = [
  SAML_SJ_ADMIN_LOGIN_PATH,
  SAML_LOGIN_PATH,
  HE_LOGIN_PATH,
  HE_SJ_ADMIN_LOGIN_PATH,
];

const App = ({
  isAuthenticated,
  trialMode,
  email,
  name,
  userId,
  isScorm,
  isVariablesLoaded,
  loadAsyncVariables,
  isNetworkError,
  hasAdminAccess,
  isSiteLoaded,
  currentTenantName,
}) => {
  useTrackTime({
    paths: [
      LESSON_PATH,
      ASSESSMENT_PATH,
      EXPERIMENT_PATH,
      MISSION_EXPERIMENT_PATH,
      CATALOG_LESSON_PATH,
      CATALOG_ASSESSMENT_PATH,
      CATALOG_EXPERIMENT_PATH,
    ],
  });

  const [isOpenChooseTenantModal, setIsOpenChooseTenantModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isSjAdmin = getIsSjAdminFromStorage();
  const hasSelectedTenant = !!currentTenantName;

  // Intercom
  // SegmentIO
  useEffect(() => {
    runIntercomScript();
    runSegmentIo();
    loadAsyncVariables();
  }, [loadAsyncVariables]);

  // Redirect to old url after login
  const [searchParams] = useSearchParams();
  const tenant = searchParams.get("t");

  // Launch Darkly Client
  const ldClient = useLDClient();
  const authenticatedUserInfo = retrieveAuthenticatedUserInfo();
  if (authenticatedUserInfo) {
    if (!email) email = authenticatedUserInfo.email;
    if (!userId) userId = authenticatedUserInfo.directory_uuid;
  }

  useEffect(() => {
    if (!email) return;
    ldClient.identify({
      kind: "user",
      key: userId,
      email: email,
      name: name,
      privateAttributeNames: ["email", "name"],
    });
  }, [email, userId]);

  // DataDog User/Tenant Information
  useEffect(() => {
    if (!email || !userId) return;
    datadogRum.setUser({
      id: userId,
      name: name,
      email: email,
      tenant: tenant,
    });
  }, [email, ldClient, name, userId, tenant]);

  // Google Tags Manager
  useEffect(() => {
    if (!trialMode) return;
    runAnalyticsScript();
  }, [trialMode]);

  // Other info
  const isRedirectAppUrl = REDIRECT_PATHS.includes(location.pathname);

  // Before rendering, figure out if we need to redirect (happens after login & site variable load)
  if (isAuthenticated && isSiteLoaded && !isSjAdmin) {
    const pathForRedirect = getPathForRedirect();
    if (pathForRedirect) {
      removePathForRedirect();
      if (pathForRedirect !== "/") {
        navigate(pathForRedirect);
      } else if (hasAdminAccess) {
        navigate(ADMIN_BASE);
      }
    }
  }

  // The various routes which can be taken
  // Anything in the memo list will could cause the app to reload
  return useMemo(() => {
    if (isNetworkError) return <NetworkError />;

    if (!isVariablesLoaded) return <FullScreenPreloader />;

    if (isRedirectAppUrl) {
      return (
        <Routes>
          <Route path={SAML_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="samlSjAdmin" />} />
          <Route path={SAML_LOGIN_PATH} element={<HeRedirectApp mode="samlUser" />} />
          <Route path={HE_LOGIN_PATH} element={<HeRedirectApp mode="user" />} />
          <Route path={HE_SJ_ADMIN_LOGIN_PATH} element={<HeRedirectApp mode="sjAdmin" />} />
        </Routes>
      );
    }

    if (!isScorm && isAuthenticated && !isSjAdmin && !hasSelectedTenant) {
      setIsOpenChooseTenantModal(true);
    } else {
      setIsOpenChooseTenantModal(false);
    }

    return (
      <Suspense fallback={<EmptyPageLoader />}>
        {isOpenChooseTenantModal && (
          <ChooseTenantModal
            isOpen={isOpenChooseTenantModal}
            setIsOpen={setIsOpenChooseTenantModal}
          />
        )}
        <>
          {!isOpenChooseTenantModal && (
            <>
              {isAuthenticated && (
                <Routes>
                  <Route path={`${ADMIN_BASE}/*`} element={<AdminFrontendApp />} />
                  <Route path="/*" element={<FrontendApp />} />
                  <Route path="/tryus/*" element={<TryUsApp />} />
                  <Route path="/guest/*" element={<ScormApp />} />
                </Routes>
              )}

              {!isAuthenticated && (
                <Routes>
                  <Route path={SJ_ADMIN_SIGN_IN_PATH} element={<SjAdminApp />} />
                  <Route path="/*" element={<LandingApp />} />
                  <Route path="/tryus/*" element={<TryUsApp />} />
                  <Route path="/guest/*" element={<ScormApp />} />
                </Routes>
              )}
            </>
          )}
        </>
      </Suspense>
    );
  }, [
    isAuthenticated,
    isVariablesLoaded,
    isRedirectAppUrl,
    isOpenChooseTenantModal,
    isNetworkError,
  ]);
};

App.propTypes = {
  isAuthenticated: T.bool.isRequired,
  userId: T.number,
  email: T.string,
  name: T.string,
  trialMode: T.bool.isRequired,
  isLoaded: T.bool,
  isScorm: T.bool,
  loadAsyncVariables: T.func.isRequired,
  isVariablesLoaded: T.bool.isRequired,
  isNetworkError: T.bool.isRequired,
  isSiteLoaded: T.bool,
  hasAdminAccess: T.bool.isRequired,
  currentTenantName: T.string,
};

const mapState = ({
  user: { id: userId, isAuthenticated, isLoaded, email, name, isScorm },
  site: { trialMode, isNetworkError, hasAdminAccess, isLoaded: isSiteLoaded, currentTenantName },
  variables: { isVariablesLoaded },
}) => ({
  isAuthenticated,
  trialMode,
  userId,
  isLoaded,
  email,
  name,
  isScorm,
  isVariablesLoaded,
  isNetworkError,
  currentTenantName,
  isSiteLoaded,
  hasAdminAccess,
});

const mapDispatch = ({ variables: { loadAsyncVariables } }) => ({ loadAsyncVariables });

export default connect(mapState, mapDispatch)(App);
